import React from 'react'
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'
import styled, { css, ThemeProvider } from 'styled-components'
import { Layout, Block, MetaTags } from '../components'
import { getLuminance } from 'polished'
import { ReactTypeformEmbed } from 'react-typeform-embed';
import JotformEmbed from 'react-jotform-embed';

import logo from '../assets/images/logo.svg'

import { container, padding, bgIcon, bgImage, hoverState } from '../styles/global';
import { grey, bronze, yellow, brown, darkBrown } from '../styles/colors';
import themes, { getThemeData } from '../styles/themes';
import { heading, paragraph, blockHeading } from '../styles/type';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { media } from '../styles/utils';


class Form extends React.Component {

	state = {

    }
    
	componentWillMount = () => {
		this.setState({
            data: this.props.pageContext.data,
            acf: JSON.parse(this.props.pageContext.data.acf_json),	
            apply_acf: JSON.parse(this.props.data.wordpressApply.acf_json),

        })
	}

    renderInfoBlocks = () => {
        const { apply_acf } = this.state;

		return apply_acf.info && apply_acf.info.map((item, i) => {            
            if(item.display){
                return (
                    <InfoItem
                        key={i}
                    >
                        <Heading>{item.heading}</Heading>
                        <Text
                            dangerouslySetInnerHTML={{__html: item.text}}  
                        />
                    </InfoItem>
                )
            }
		})
	}


	render() {       
        const { data, acf, apply_acf } = this.state;
        
		return (
			<Layout
                theme={'residential-architecture'} 
                footerImage={apply_acf.footer_bg_image && apply_acf.footer_bg_image.sizes.xlarge}
			>
                <MetaTags
                    title={data.title}
                    description={''}
                />
				<Wrapper>
					<Header
						showNav={true}
						showSubmit={true}
						fixed={true}
                        theme={{
                            colour: brown,
                            background: grey
                        }}
					/>

                    <Container>

                        <Title>Application Form</Title>
                        <SubTitle>{data.title}</SubTitle>

                        <FormContainer>

                            <JotformEmbed 
                                src="https://form.jotform.com/211009005023838" 
                            />

                            {/* <ReactTypeformEmbed 
                                url={`${acf.typeform_url}?category=${data.slug}`}
                                hideFooter={true}
                                // hideHeaders={true}
                                //popup={false}
                                // autoOpen={false}
                            /> */}

                        </FormContainer> 

                        <InfoBlocks>
                            {this.renderInfoBlocks()}  
                        </InfoBlocks>

                    </Container>
			

				</Wrapper>
			</Layout>	
		)
	}
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`
const Container = styled.div`
    ${container};
    ${padding};
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`
const FormContainer = styled.div`
    position: relative;
    height: 600px;
    width: 100%;
    margin-bottom: 100px;

    ${media.phone`
        height: 80vw;
        width: 100vw;
    `}

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100px;
        background: linear-gradient(180deg, rgba(219,214,208,1) 0%, rgba(210,210,210, 0) 100%);
        z-index: 1;

        ${media.phone`
            height: 48px;
        `}
    }
`

const Title = styled.div`
    ${heading};
    text-transform: uppercase;
    max-width: 520px;
    text-align: center;
    margin-top: 145px;
    margin-bottom: 40px;

    ${media.phone`
        margin-top: 96px;
        margin-bottom: 20px;
	`}
`

const SubTitle = styled.div`
    ${heading};
    font-size: 24px;
    text-transform: uppercase;
    max-width: 520px;
    text-align: center;

	${media.phone`
		font-size: 20px;
        margin-bottom: 40px;
	`}
`


const Heading = styled.div``
const Text = styled.div``

const InfoBlocks = styled.div` 
    display: flex;
    flex-direction:column;
    align-items: center;
`

const InfoItem = styled.div`
    display: flex; 
    border-top: 1px solid ${darkBrown};
    padding-top: 40px;
    margin-bottom: 80px;

    ${Heading} {
        flex: 0 1 50%;

        ${blockHeading}
        font-weight: bold;
        margin-bottom: 0;
        border: 0;
    }

    ${Text} {
        flex: 0 1 50%;
        p {
            color: ${darkBrown};
            ${paragraph};
        }
    }

    ${media.phone`
        flex-direction: column;
        margin-bottom: 48px;
    `}
`

export default Form


export const query = graphql`
	query {
		wordpressApply {
			acf_json	
		}
    }
`
